function testForFlex(d) {
  var e = d.createElement('b'),
    f = 'flex',
    fw = '-webkit-' + f;
  try {
    e.style.display = fw;
    e.style.display = f;
    return e.style.display === f || e.style.display === fw;
  } catch (err) {
    return false;
  }
}

function testForInternetExplorer() {
  var ua = navigator.userAgent;

  // MSIE used to detect IE 6-10 and Trident used to detect IE11
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}

function testForStringIncludes() {
  return String.prototype.includes ? true : false;
}

export default function () {
  // browser supported if it is not internet explorer, has flex support,
  // and has String.prototype.includes (minimum es6 that should be taken care of by babel)
  var isSupportedBrowser =
    !testForInternetExplorer() &&
    testForFlex(document) &&
    testForStringIncludes();

  return isSupportedBrowser;
}

export function appendBrowserWarning() {
  var div = document.createElement('div');

  div.className = 'browser-warning';

  div.innerHTML = `
    <h1>This browser (or configuration) doesn&#8217;t support Membean</h1>
    <p>
      We built Membean to work with modern browsers so it&#8217;s fast and
      secure. Unfortunately, your browser isn&#8217;t currently compatible.
      Please try the following:
    </p>
    <ul>
      <li>Ensure that you have JavaScript enabled.</li>
      <li>Ensure that you have cookies enabled.</li>
      <li>Switch to one of these fine browsers and you&#8217;ll be on your way!</li>
    </ul>
    <ul class="browser-list list-inline">
      <li class="safari">
        <a href="https://support.apple.com/downloads/safari">
          <img src="https://cdn0.membean.com/public/cdnimages/safari.gif" alt="Safari Logo" />
          <span>Apple </span>Safari
        </a>
      </li>
      <li v="chrome">
        <a href="https://www.google.com/chrome/">
          <img src="https://cdn0.membean.com/public/cdnimages/chrome.gif" alt="Chrome Logo" />
          <span>Google </span>Chrome
        </a>
      </li>
      <li v="edge">
        <a href="https://www.microsoft.com/windows/microsoft-edge">
          <img src="https://cdn0.membean.com/public/cdnimages/edge.gif" alt="Edge Logo" />
          <span>Microsoft </span>Edge
        </a>
      </li>
      <li class="firefox">
        <a href="https://www.mozilla.org/firefox/">
          <img src="https://cdn0.membean.com/public/cdnimages/firefox.gif" alt="Firefox Logo" />
          <span>Mozilla </span>Firefox
        </a>
      </li>
    </ul>
  `;

  document.getElementById('react').appendChild(div);
}
