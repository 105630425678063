import React, { useState } from 'react';
import Icon from 'framework/components/Icon';
import SkipLinks from 'framework/components/SkipLinks/SkipLinks';
import MinimalCard from 'framework/components/Card/Minimal';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotEmailCard from './ForgotEmailCard';

/*
  Renders the ForgotPasswordPage
  
  Usage:
    
    <ForgotPasswordPage />
  
  Includes ForgotPasswordForm and Forgot Email Modal.

  */

const ForgotPasswordPage = () => {
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = e => {
    if (e) {
      e.preventDefault();
    }
    return setHideModal(!hideModal);
  };

  const forgotPasswordHtml = (
    <MinimalCard heading="Forgot your password?" logo={<Icon name={'logo'} />}>
      <p>
        It happens to the best of us. Enter your email below, and we&apos;ll
        send you instructions to reset your password.
      </p>
      <ForgotPasswordForm handleForgotEmail={toggleModal} />
    </MinimalCard>
  );

  return (
    <React.Fragment>
      <SkipLinks />
      {hideModal ? (
        forgotPasswordHtml
      ) : (
        <ForgotEmailCard goBack={toggleModal} />
      )}
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
