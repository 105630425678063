/*
  Validators for Login pack
  Note: A membean user login can be username or email
*/

const ERROR_LOGIN = 'Please enter your email or username.';
const ERROR_PASSWORD = 'Please enter your password.';
const ERROR_EMAIL = 'Please enter a valid email address.';

const isValidLogin = login => {
  return login && login.length > 0;
};

const isValidPassword = password => {
  return password && password.length > 0;
};

// Need to include email validation on /forgot_password
const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const isValidEmail = value => {
  const email = value.toLowerCase();
  return email && email.length > 0 && emailRegex.test(email);
};

export const validateLogin = values => {
  const errors = {};
  if (!isValidLogin(values.username)) {
    errors.username = ERROR_LOGIN;
  }
  if (!isValidPassword(values.password)) {
    errors.password = ERROR_PASSWORD;
  }
  return errors;
};

export const validateEmail = values => {
  const errors = {};
  if (!isValidEmail(values.email)) {
    errors.email = ERROR_EMAIL;
  }
  return errors;
};
