import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Loader from 'framework/components/Card/Loader';
import Icon from 'framework/components/Icon';
import FullScreenBackButton from 'framework/components/FullScreenBackButton';
import { useNavigate } from 'react-router-dom';

export default function FullScreenCard(props) {
  const { title, children, className, goBack, helpUrl, subTitle, render } =
    props;
  const navigate = useNavigate();

  const handleCloseAction = () => {
    if (goBack) {
      return goBack();
    } else {
      // go up one route by default, ex /dashboard/all-words => /dashboard
      return navigate(-1);
    }
  };

  const focusTarget = useRef(null);

  useEffect(() => {
    focusTarget?.current && focusTarget.current.focus();
  }, []);

  return (
    <div data-testid="fullscreen-card" className="fullscreen-card">
      <div className="row">
        <div className="columns extra-small-12 medium-12">
          <div className="fullscreen-card-header">
            <div className="fullscreen-card-sub-header">
              <strong dangerouslySetInnerHTML={{ __html: subTitle }} />
            </div>
            <div className="fullscreen-card-title">
              <h1 ref={focusTarget} tabIndex="-1">
                {title}
              </h1>
            </div>
            <div className="fullscreen-card-actions">
              {helpUrl && (
                <a
                  aria-label={`Learn more about ${title}`}
                  href={helpUrl}
                  className="action"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="help" />
                </a>
              )}
              <button
                className="action"
                id="fullscreen-card-close-button"
                aria-label={`Close ${title}`}
                onClick={handleCloseAction}
              >
                <Icon name="close" />
              </button>
            </div>
          </div>
        </div>

        <div className="columns extra-small-12 medium-12">
          {render ? (
            render()
          ) : (
            <main
              className={`card fullscreen${className ? ' ' + className : ''}`}
            >
              <div className={'card-content'}>{children || <Loader />}</div>
            </main>
          )}
        </div>

        <div className="columns extra-small-12 medium-12">
          <FullScreenBackButton backAction={handleCloseAction} />
        </div>
      </div>
    </div>
  );
}

FullScreenCard.propTypes = {
  title: PropTypes.string,
  footerAction: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  goBack: PropTypes.func,
  subTitle: PropTypes.string,
  render: PropTypes.func
};
