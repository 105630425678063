import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import FlashAlerts from 'framework/components/FlashAlerts';
import SamlPage from './components/SamlSignInPage';
import CheckRevision from 'framework/components/CheckRevision';

/*
  Routes for user login pages, should also show flash alerts coming from Rails
*/

const Login = () => {
  const [flashAlerts, setFlashAlerts] = useState(window.__ALERTS__ || []);

  const updateFlashAlerts = alert => setFlashAlerts([...flashAlerts, alert]);

  return (
    <BrowserRouter>
      <FlashAlerts flashAlerts={flashAlerts} setFlashAlerts={setFlashAlerts} />
      <CheckRevision />
      <Routes>
        <Route
          path="login"
          element={<LoginPage updateFlashAlerts={updateFlashAlerts} />}
        />
        <Route path="forgot_password" element={<ForgotPasswordPage />} />
        <Route path="saml_signin" element={<SamlPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Login;
