import React from 'react';
import Icon from 'framework/components/Icon';
import SkipLinks from 'framework/components/SkipLinks/SkipLinks';
import MinimalCard from 'framework/components/Card/Minimal';
import SamlSignInForm from './SamlSignInForm';

const SamlSignInPage = () => {
  return (
    <React.Fragment>
      <SkipLinks />
      <MinimalCard heading="Sign In with SAML" logo={<Icon name={'logo'} />}>
        <p>
          Enter your email below to sign in using your school&apos;s SAML
          provider.
        </p>
        <SamlSignInForm />
      </MinimalCard>
    </React.Fragment>
  );
};

export default SamlSignInPage;
