import React from 'react';
import PropTypes from 'prop-types';
import FullScreenCard from 'framework/components/FullScreenCard';
import Card from 'framework/components/Card';

/*
  Renders the ForgotEmailCard on /forgot_password. Wrapper for FullscreenCard
  
  Usage:
    
    <ForgotEmailCard goBack={callbackFn} />
  
  Props:

    goBack [Func] - The callback function when a user clicks on close and back inside this card.

  */

const ForgotEmailCard = props => {
  const { goBack } = props;
  return (
    <div className="fullscreen-card">
      <FullScreenCard
        title="Forgot your email?"
        goBack={goBack}
        render={() => (
          <Card>
            <h2>I use Membean at school.</h2>
            <p>
              If you use Membean at school and you are having trouble signing
              in, please contact your teacher. They can easily reset your
              password for you. Otherwise, feel free to contact{' '}
              <a href="mailto:support@membean.com">Membean Support</a> with your
              full name and school, and we&apos;ll be happy to help you.
            </p>
            <br />
            <h2>I do not use Membean at school.</h2>
            <p>
              Please contact{' '}
              <a href="mailto:support@membean.com">Membean Support</a> with your
              full name and username, and we&apos;ll be happy to help you with
              resetting your password.
            </p>
          </Card>
        )}
      />
    </div>
  );
};

ForgotEmailCard.propTypes = {
  goBack: PropTypes.func.isRequired
};

export default ForgotEmailCard;
