import React from 'react';
import { createRoot } from 'react-dom/client';
import 'login/styles/index.scss';
import Login from 'login';
import checkForSupportedBrowser, {
  appendBrowserWarning
} from 'framework/helpers/checkForSupportedBrowser';
import { cookiesEnabled } from 'framework/helpers/cookies';
import SentryWrapper from 'framework/components/SentryWrapper';

// TODO: SHow page or alert for cookies failures
if (checkForSupportedBrowser() && cookiesEnabled()) {
  const isProd = process.env.NODE_ENV === 'production'; // eslint-disable-line

  window.isProd = isProd;

  const container = document.getElementById('react');
  const root = createRoot(container);

  root.render(
    <SentryWrapper packName="login">
      <Login />
    </SentryWrapper>
  );
} else {
  appendBrowserWarning();
}
