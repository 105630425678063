import React, { useEffect } from 'react';
import LoginForm from './LoginForm';
import SSOLoginSection from './SSOLoginSection';
import MinimalCard from 'framework/components/Card/Minimal';
import SkipLinks from 'framework/components/SkipLinks/SkipLinks';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = props => {
  const location = useLocation();
  const navigate = useNavigate();

  // remove query param from url on page load and display alert if necessary
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('alert')) {
      const alertType = queryParams.get('alert');

      if (alertType === 'unauthorized') {
        props.updateFlashAlerts({
          title: 'Unauthorized',
          description: 'Please sign in again.',
          dismissable: true,
          type: 'error'
        });
      }

      queryParams.delete('alert');
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, []);

  return (
    <React.Fragment>
      <SkipLinks />
      <MinimalCard heading={'Please sign in to continue'}>
        <LoginForm />
        <SSOLoginSection />
      </MinimalCard>
    </React.Fragment>
  );
};

export default LoginPage;
