import React, { useState } from 'react';
import { attemptSamlSignIn } from '../api/requests';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import Icon from 'framework/components/Icon';
import { TextInput } from '@membean/react-uikit';
import { validateEmail } from 'login/validators';
import { Link } from 'react-router-dom';

export const SamlSignInForm = () => {
  const [httpState, setHttpState] = useState({
    loading: false,
    errors: '',
    successMessage: ''
  });

  const { loading } = httpState;

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      onSubmit={values => {
        setHttpState({ ...httpState, loading: true });
        return attemptSamlSignIn(
          values,
          successMessage =>
            setHttpState({
              ...httpState,
              loading: false,
              errors: '',
              successMessage
            }),
          (err, status) => {
            if (status === 401) {
              location.reload();
            }
            setTimeout(() => {
              setHttpState({ ...httpState, errors: '' });
            }, 15000);
            setHttpState({
              ...httpState,
              loading: false,
              errors: err,
              successMessage: ''
            });
          }
        );
      }}
      validate={validateEmail}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {formik => {
        const {
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          dirty,
          isValid
        } = formik;
        return (
          <Form id="saml-login">
            <div className="control-with-help">
              <TextInput
                feedbackContext={'error'}
                feedbackText={touched.email && errors.email}
                id="email"
                type="email"
                autoComplete="email"
                isValid={touched.email && !errors.email}
                label="Email Address"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                autoFocus
              />
            </div>
            <div
              className={classnames('alert alert-inline', {
                'alert-error': httpState.errors,
                'alert-success': httpState.successMessage
              })}
              hidden={!httpState.errors && !httpState.successMessage}
              role="alert"
              aria-live="assertive"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: httpState.errors || httpState.successMessage
                }}
              ></p>
            </div>
            <div className="centered-controls">
              <button
                className="btn-call-to-action btn btn-blue btn-full-mobile-only btn-icon-right"
                disabled={!dirty || !isValid}
                type="submit"
                aria-label="Send"
              >
                {loading ? 'Signing In...' : 'Sign In'}
                <Icon name="caret-right" />
              </button>
            </div>
            <div
              className={classnames(
                'help-controls',
                'forgot-password-controls'
              )}
            >
              <div className="help-link">
                <div className="return-to-login-link">
                  <Link to="/login">
                    <Icon name="caret-left" /> Return to login
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SamlSignInForm;
