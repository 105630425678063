import React from 'react';
import { SSOButton } from './SSOButton';

/*
  Renders a list of SSO Providers on /login.
  
  Usage:
    
    <SSOLoginSection />
  
  */

export const SSOLoginSection = () => {
  return (
    <section className="sso-login" data-testid="sso-login-section">
      <ul>
        <li>
          <SSOButton provider="google" text="Sign In with Google" />
        </li>
        <li>
          <a href="/saml_signin" className="btn btn-white btn-small">
            Sign In with SAML
          </a>
        </li>
      </ul>
      <p>
        Not sure if your school has enabled single sign-on for Membean? Ask your
        teacher.
      </p>
    </section>
  );
};

export default SSOLoginSection;
