export const createCookie = (name, value, days) => {
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toGMTString()}; path=/`;
  } else {
    document.cookie = `${name}=${value}; path=/`;
  }
};

export const eraseCookie = name => {
  createCookie(name, '');
};

export const cookiesEnabled = () => {
  if (navigator.cookieEnabled) return true;
  createCookie('mb_is_cookie_enabled', '1');
  const cookiesEnabled = document.cookie.indexOf('mb_is_cookie_enabled') !== -1;
  eraseCookie('mb_is_cookie_enabled');
  return cookiesEnabled;
};
