import React, { useEffect } from 'react';
import Alert from 'framework/components/Alert';

const FlashAlerts = props => {
  const { flashAlerts, setFlashAlerts } = props;

  useEffect(() => {
    window.__ALERTS__ = null;
    if (flashAlerts) {
      const htmlBody = document.getElementById('top');
      const alertScript = document.getElementById('hydrate-alerts');
      if (alertScript) {
        htmlBody.removeChild(alertScript);
      }
    }
  }, [flashAlerts]);

  const dismissAlert = alert => {
    setFlashAlerts(
      flashAlerts.filter(item => {
        return item !== alert;
      })
    );
  };

  if (!flashAlerts.length) return null;

  return (
    <div>
      {flashAlerts.map((alert, i) => {
        return (
          <Alert
            alert={alert}
            key={`flash-alert$-${i}`}
            dismissCallback={dismissAlert}
          />
        );
      })}
    </div>
  );
};

export default FlashAlerts;
