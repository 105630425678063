import React, { useState } from 'react';
import { attemptLogin } from '../api/requests';
import { CheckBoxInput, TextInput, Tooltip } from '@membean/react-uikit';
import { Formik, Form } from 'formik';
import Icon from 'framework/components/Icon';
import { validateLogin } from 'login/validators';

/*
  Renders the Login Form
  
  Usage:
    
    <LoginForm />

  */

export const LoginForm = () => {
  const [httpState, setHttpState] = useState({
    loading: false,
    errors: ''
  });

  const [showPassword, setShowPassword] = useState(false);

  const { loading } = httpState;
  const defaultOptions = {
    username: '',
    password: '',
    rememberMe: false
  };

  return (
    <Formik
      initialValues={defaultOptions}
      onSubmit={values => {
        setHttpState({ ...httpState, loading: true });
        return attemptLogin(values, err => {
          setHttpState({
            ...httpState,
            loading: false,
            errors: err,
            success: false
          });
        });
      }}
      validate={validateLogin}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formik => {
        const {
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          dirty,
          isValid,
          isSubmitting
        } = formik;
        return (
          <Form id="login">
            <div className="control-with-help">
              <TextInput
                id="username"
                label="Email or Username"
                name="username"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                isValid={touched.username && !errors.username}
                feedbackText={touched.username && errors.username}
                autoComplete="username email"
                autoFocus
              />
            </div>
            <div className="control-with-help control-with-checkbox">
              <TextInput
                id="password"
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                feedbackText={touched.password && errors.password}
                autoComplete="current-password"
              />
              <div className="show-password">
                <CheckBoxInput
                  id="showPassword"
                  label="Show password"
                  name="showPassword"
                  onChange={() => {
                    setShowPassword(!showPassword);
                  }}
                  removeFromTabOrder
                  value={showPassword}
                />
              </div>
            </div>
            <div
              className="alert alert-inline alert-error"
              hidden={!httpState.errors}
              role="alert"
              aria-live="assertive"
            >
              {httpState.errors && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: httpState.errors
                  }}
                ></p>
              )}
            </div>
            <div className="centered-controls">
              <button
                className="btn-call-to-action btn btn-blue btn-full-mobile-only btn-icon-right"
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                aria-label="Sign In"
              >
                {loading ? 'Signing In' : 'Sign In'}
                <Icon name="caret-right" />
              </button>
            </div>
            <div className="centered-controls ">
              <CheckBoxInput
                classes="remember-me-control"
                id="rememberMe"
                label="Keep me signed in"
                name="rememberMe"
                onChange={handleChange}
                value={values.rememberMe}
              />
            </div>
            <div className="help-controls">
              <div className="help-link">
                <Tooltip
                  id="forgot-login-tooltip"
                  text="Forgot username?"
                  tooltipBody={`If you use Membean at school, your username looks like "firstname_lastname_schoolcode". Ask you teacher for help.`}
                />
              </div>
              &#183;
              <div className="help-link">
                <a href="/forgot_password">Forgot password?</a>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
