import React from 'react';
import Icon from 'framework/components/Icon';
import PropTypes from 'prop-types';

export default function FullScreenBackButton(props) {
  return (
    <div className="row">
      <div className="small-12 columns text-center">
        <button
          className="btn btn-full-mobile-only btn-blue btn-icon-left close-memlet-btn"
          onClick={props.backAction}
        >
          <Icon name="arrow-left" />
          Back
        </button>
      </div>
    </div>
  );
}

FullScreenBackButton.propTypes = {
  backAction: PropTypes.func.isRequired
};
