import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'framework/components/Card/Loader';

export class Card extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestid: PropTypes.string,
    direction: PropTypes.string,
    footerAction: PropTypes.node,
    label: PropTypes.string,
    primaryAction: PropTypes.node,
    title: PropTypes.node,
    type: PropTypes.string
  };

  render() {
    const {
      children,
      className,
      dataTestid = 'card',
      direction = 'vertical',
      footerAction,
      label,
      primaryAction,
      title,
      type
    } = this.props;
    let classNames = ['card'];
    if (className) {
      classNames.push(className);
    }
    if (type) {
      classNames.push(type);
    }
    return (
      <section data-testid={dataTestid} className={classNames.join(' ')}>
        {title || primaryAction ? (
          <div className={'card-header'}>
            <h2 className="title" aria-label={label}>
              {title}
            </h2>
            {primaryAction}
          </div>
        ) : null}
        <div className={`card-content ${direction}`}>
          {children || <Loader />}
          {footerAction && <div className="card-footer">{footerAction}</div>}
        </div>
      </section>
    );
  }
}

export default Card;
