import React, { useCallback, useEffect, useState } from 'react';
import { attemptForgotPassword } from '../api/requests';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import Icon from 'framework/components/Icon';
import { TextInput } from '@membean/react-uikit';
import { validateEmail } from 'login/validators';
import { Link } from 'react-router-dom';
import _throttle from 'lodash.throttle';

/*
  Renders the ForgotPasswordForm on /forgot_password
  
  Usage:
    
    <ForgotPasswordForm handleForgotEmail={toggleModal} />
  
  Props:

    handleForgotEmail [Func] - The callback function when a user clicks on Forgot Email inside this form.

  */

export const ForgotPasswordForm = props => {
  const { handleForgotEmail } = props;

  const [httpState, setHttpState] = useState({
    loading: false,
    errors: '',
    successMessage: ''
  });

  const { loading } = httpState;

  /*
    We throttle the request sent when a user hits the "Send" button to
    prevent rage clicking, which will generate multiple emails. Currently
    set to only allow a request every 500ms.
  */
  const throttledSubmit = useCallback(
    _throttle((values, feedbackMessageHandler) => {
      setHttpState(prevState => ({ ...prevState, loading: true })); // We set loading to true before making the API call. This will disable the "Send" button.
      attemptForgotPassword(
        values,
        successMessage =>
          feedbackMessageHandler({
            loading: false,
            errors: '',
            successMessage
          }),
        (err, status) => {
          if (status === 401) {
            location.reload();
          }
          setTimeout(() => {
            feedbackMessageHandler({ errors: '' });
          }, 15000);
          feedbackMessageHandler({
            loading: false,
            errors: err,
            successMessage: ''
          });
        }
      );
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      throttledSubmit.cancel();
    };
  }, []);

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        setHttpState(prevState => ({ ...prevState, loading: true }));
        throttledSubmit(values, update => {
          setHttpState(prevState => ({ ...prevState, ...update }));
          setSubmitting(false);
        });
        // Disable the button until the throttle period is over or the response comes back
        setSubmitting(true);
        //  setTimeout is used here to set setSubmitting(false) after 500ms, which will enable the button again. This ensures that the button stays disabled for at least 500ms
        setTimeout(() => {
          setSubmitting(false);
        }, 500);
      }}
      validate={validateEmail}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {formik => {
        const {
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          dirty,
          isValid
        } = formik;
        return (
          <Form id="forgot-password">
            <div className="control-with-help">
              <TextInput
                feedbackContext={'error'}
                feedbackText={touched.email && errors.email}
                id="email"
                isValid={touched.email && !errors.email}
                label="Email Address"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                autoComplete="email"
                value={values.email}
                autoFocus
              />
            </div>
            <div
              className={classnames('alert alert-inline', {
                'alert-error': httpState.errors,
                'alert-success': httpState.successMessage
              })}
              hidden={!httpState.errors && !httpState.successMessage}
              role="alert"
              aria-live="assertive"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: httpState.errors || httpState.successMessage
                }}
              ></p>
            </div>
            <div className="centered-controls">
              <button
                className="btn-call-to-action btn btn-blue btn-full-mobile-only btn-icon-right"
                disabled={!dirty || !isValid || loading}
                type="submit"
                aria-label="Send"
              >
                {loading ? 'Sending...' : 'Send'}
                <Icon name="caret-right" />
              </button>
            </div>
            <div
              className={classnames(
                'help-controls',
                'forgot-password-controls'
              )}
            >
              <div className="help-link">
                <div className="return-to-login-link">
                  <Link to="/login">
                    <Icon name="caret-left" /> Return to login
                  </Link>
                </div>
              </div>
              &#183;
              <div className="help-link">
                <a href="javascript:void(0)" onClick={handleForgotEmail}>
                  Forgot email?
                </a>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
