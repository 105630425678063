import fetchRetry from 'framework/api/fetchRetry';
import buildPostHeaders from 'framework/helpers/buildPostHeaders';
import getCsrfFromDOM from 'framework/helpers/getCsrfFromDOM';

/* 
  Attempts login with username or email 
  Removes leading and trailing whitespace for both username and email.
*/

export const attemptLogin = (formState, failure) => {
  return fetchRetry('/login', {
    body: JSON.stringify({
      user: {
        username: formState.username.trim(),
        password: formState.password,
        remember_me: formState.rememberMe
      }
    }),
    headers: buildPostHeaders(),
    method: 'POST'
  })
    .then(res => res.json())
    .then(json => {
      const responseBody = json.payload || json;
      if (responseBody && responseBody.errors) {
        return failure(responseBody.errors);
      }
    })
    .catch(err => {
      failure(err && err.message);
    });
};

const initSSO = authorize_url => {
  const form = document.createElement('form');
  form.action = authorize_url;
  form.method = 'POST';
  const input = document.createElement('input');
  input.setAttribute('type', 'hidden');
  input.setAttribute('name', 'authenticity_token');
  input.setAttribute('value', getCsrfFromDOM());
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
};

/*
  Attempts google sso Sign
*/

export const googleSSOLogin = (success, failure) => {
  return fetchRetry('/google_oauth_signin')
    .then(res => res.json())
    .then(json => {
      const responseBody = json.payload || json;
      if (responseBody && responseBody.errors) {
        return failure(responseBody.errors);
      } else {
        success();
        initSSO(json.authorize_url);
      }
    })
    .catch(err => failure(err && err.message));
};

/*
  Attempts Saml Sign in with email
  Removes leading and trailing whitespace for email.
*/

export const attemptSamlSignIn = (formState, _, failure) => {
  return fetchRetry('/saml_signin', {
    body: JSON.stringify({
      email: formState.email.trim()
    }),
    headers: buildPostHeaders(),
    method: 'POST'
  })
    .then(res => res.json())
    .then(json => {
      const responseBody = json.payload || json;
      if (responseBody && responseBody.errors) {
        return failure(responseBody.errors);
      } else {
        return fetchRetry(responseBody.goto)
          .then(res => res.json())
          .then(res => initSSO(res.authorize_url));
      }
    })

    .catch(err => {
      failure(err && err.message);
    });
};

/* 
  Attempts triggering forgot email send
  Removes leading and trailing whitespace for the users email.
*/

export const attemptForgotPassword = (formState, success, failure) => {
  return fetchRetry('/users/password', {
    body: JSON.stringify({
      user: {
        email: formState.email.trim()
      }
    }),
    headers: buildPostHeaders(),
    method: 'POST'
  })
    .then(res => {
      if (res.status === 204) {
        return success('Please check your email for instructions.');
      } else {
        return res.json().then(json => {
          const responseBody = json.payload || json;
          if (responseBody && responseBody.errors) {
            return failure(responseBody.errors);
          }
        });
      }
    })
    .catch(err => {
      failure(err?.message, err?.status);
    });
};
