import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'framework/components/Icon';

/*
  Renders a Minimal Card component which displays a title with logo inside
  Horizontally centered at $breakpoint-md (480px)
  Will focus on heading prop on mount
  
  Usage:
    
  <MinimalCard
    heading="Card Title"
    logo={
      <img/>
    }
  >
    <Foo/>
    <Bar/>
  </MinimalCard>
  
  Props:

    heading [String] - heading of card

    logo [Node] - <img/> or <svg/> of logo or brand to display

    children [Node] - children to display inside card component, if any
  
  */

export default function MinimimalCard(props) {
  const { heading, children } = props;

  return (
    <React.Fragment>
      <div className="minimal-card-container">
        <div className="minimal-card">
          <div className="minimal-card-title">
            <a href="/">
              <Icon name={'logo'} />
            </a>
          </div>
          <main className="minimal-card-content" id="content">
            <h1 tabIndex="-1">{heading}</h1>
            {children}
          </main>
        </div>
      </div>
      <footer className="minimal-card-footer" id="footer">
        <ul className="list list-inline">
          <li>
            <a
              href="//support.membean.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Support
            </a>
          </li>
          <li>
            <a href="/agreement" rel="noopener noreferrer" target="_blank">
              Terms of Use
            </a>
          </li>
          <li>
            <a href="/privacy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
          </li>
        </ul>
      </footer>
    </React.Fragment>
  );
}

MinimimalCard.propTypes = {
  heading: PropTypes.string,
  logo: PropTypes.node,
  children: PropTypes.node
};
