import React, { useState } from 'react';
import PropTypes from 'prop-types';
import logoGoogleSvg from 'framework/images/sso-logo-google.svg';
import logoMicrosoftSvg from 'framework/images/sso-logo-microsoft.svg';
import { googleSSOLogin } from '../api/requests';

/*
  Renders a SSOButton Component, import provider logo svg before using.
  
  Usage:
    
    <SSOButton
      provider="google"
      text="Sign In with Google"
    />
  
  Props:

    provider [String] - name of sso provi der.

    text [String] - text for the button, if any
  
  */

const providerLogoMap = {
  google: logoGoogleSvg,
  microsoft: logoMicrosoftSvg
};

export const SSOButton = props => {
  const { provider, text } = props;
  const providerLogo = providerLogoMap[provider] || null;
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <button
        onClick={() =>
          googleSSOLogin(
            () => setErrorMessage(''),
            err => {
              setErrorMessage(err);
            }
          )
        }
        className={`sso-login-btn sso-login-btn-${provider} ${errorMessage ? 'margin-bottom' : ''}`}
        aria-label={`${text}`}
        data-testid="sso-login-button"
      >
        <div className="sso-login-btn-icon">
          <img alt={`${provider} logo`} src={providerLogo} />
        </div>
        {text}
      </button>
      {errorMessage && (
        <div
          className="alert alert-inline alert-error"
          hidden={!errorMessage}
          role="alert"
          aria-live="assertive"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: errorMessage
            }}
          ></p>
        </div>
      )}
    </>
  );
};

SSOButton.propTypes = {
  provider: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default SSOButton;
